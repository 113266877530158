import { useCallback, useState } from 'react';
import TypeWriter from '../TypeWriter/TypeWriter';
import classes from './Home.module.scss';

const typeTexts = [
    "Tens of exposed people and entities (for now...)",
    "Many encrypted classified documents waiting for your hacking skills",
    "Thousands of outrageous compromising data",
];

function Home() {
    const [typedTextIndex, setTypedTextIndex] = useState(0);

    const changeTypedTextHandler = useCallback(() => {
        setTypedTextIndex((current) => {
            current++;
            if(current >= typeTexts.length){
                current = 0;
            }
            return current;
        })
    }, [setTypedTextIndex]);

    return (
    <div className={classes.home}>
        <div className={classes.banner}>
            <div className={classes.main}>
                <div className={classes.firstTitle}>Join the <span className={classes.highlight}>C-Leak</span> group and become part of the most praised and feared hacktivist community</div>
                <div className={classes.secondTitle}>Earn prestige and crypto coins by helping to disclose leaked documents, expose people, companies, governments and much more in this new <span className={classes.highlight}>riddle solving game</span></div>
                <a className={classes.terminalBtn} href="/terminal">Open Terminal</a>
            </div>
        </div>
        <div className={classes.info}>
            <TypeWriter text={typeTexts[typedTextIndex]} onFinished={changeTypedTextHandler}></TypeWriter>
        </div>
    </div>
    );
}

export default Home;