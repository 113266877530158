import { useEffect } from "react";

const Page = (props) => {
  useEffect(() => {
    document.title = `${ props.title ? `${props.title} - ` : ""}${process.env.REACT_APP_SITE_TITLE}`;
  }, [props.title]);
  
  return props.children;
};

export default Page;