import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import Privacy from './components/Privacy/Privacy';
import Terms from './components/Terms/Terms';
import About from './components/About/About';
import ContactUs from './components/ContactUs/ContactUs';
import Page from './components/Page/Page';
import HowToPlay from './components/HowToPlay/HowToPlay';
import Faq from './components/Faq/Faq';

function App() {
  const showLegal = false;

  return (
    <>
      <Router>
        <Switch>
          <Route path="/about" render={
            (props) => (
              <Page title="About">
                <About {...props}/>
              </Page>
            )
          }>
          </Route>
          <Route path="/contact" render={
            (props) => (
              <Page title="Contact Us">
                <ContactUs {...props}/>
              </Page>
            )
          }>
          </Route>
          {showLegal && <Route path="/terms" render={
            (props) => (
              <Page title="Terms of Use">
                <Terms {...props}/>
              </Page>
            )
          }>
          </Route>}
          {showLegal && <Route path="/privacy" render={
            (props) => (
              <Page title="Privacy Policy">
                <Privacy {...props}/>
              </Page>
            )
          }>
          </Route>}
          <Route path="/howtoplay" render={
            (props) => (
              <Page title="How To Play">
                <HowToPlay {...props}/>
              </Page>
            )
          }>
          </Route>
          <Route path="/faq" render={
            (props) => (
              <Page title="FAQ">
                <Faq {...props}/>
              </Page>
            )
          }>
          </Route>
          <Route path="/" render={
            (props) => (
              <Page>
                <Home {...props}/>
              </Page>
            )
          }>
          </Route>
        </Switch>
      </Router>
      <Footer/>
    </>
  );
} 

export default App;
