import React from "react";

import twitterLogo from '../../assets/twitter.svg';
import facebookLogo from '../../assets/facebook.png';
import instagramLogo from '../../assets/instagram.png';
import classes from './ContactUs.module.scss';

const ContactUs = React.memo(() => {
    return (
        <div className={classes.contactUs}>
            <p>Regarding all enquiries, please reach us in any of our social medias:<br/></p>
            <div className={classes.socialNetworks}>
                <a href="https://twitter.com/c_leak_official"target="_blank" rel="noreferrer" ><img src={twitterLogo} alt="twitter"></img><span> Twitter</span></a>
                <a href="https://www.facebook.com/c.leak.official"target="_blank" rel="noreferrer" ><img src={facebookLogo} alt="facebook"></img><span> Facebook</span></a>
                <a href="https://www.instagram.com/c.leak.official"target="_blank" rel="noreferrer" ><img src={instagramLogo} alt="instagram"></img><span> Instagram</span></a>
            </div>
            <p><small>Don't forget to also follow us and stay tuned with all the updates.</small></p>
        </div>
    );
});

export default ContactUs;