import React from "react";

import classes from './HowToPlay.module.scss';

const HowToPlay = React.memo(() => {
    return (
        <div className={classes.howToPlay}>
            <h1 className={classes.break}>How To Play</h1>
            <p>All leaked files are accessible through the C-Leak <a href="/terminal">Terminal</a>. They are separete into folders with content related to the attack we have made. Some of these files will be unprotected and are ready to be explored. Others will have its content encrypted with a password.
            <b> Your goal is to discover their password and decrypt these files and, by doing so, you end up with pieces of a wallet private key that may still be full of crypto coins</b>.</p>
            <br/>

            <h3>Strategy:</h3>
            <p>The password discovering method may vary file to file. It can be methods like solving riddles, puzzles, mathematical problems, lateral thinking; or image, videos, sound and other file data manipulation; or external accessible content; and much more.</p>
            <br/>

            <h3>Mechanics:</h3>
            <p>The way you navigate through the <a href="/terminal">Terminal</a> is very similar to any <a href="https://en.wikipedia.org/wiki/Command-line_interface">command line interface</a> available out there. A list of all available commands can be found by typing the <code><i>help</i></code> command. You can also interact with files, folders and programs by simply clicking on them (this work as a shortcut to typing the command line).</p>

            <br/>
            <h3>Stuck?</h3>
            <p>Whenever stuck into a challenge, you can freely go to another one. All leaks and their encrypted files are available from the start, although resolving them in sequence is key for understanding the narrative.</p>
        </div>
    );
});

export default HowToPlay;