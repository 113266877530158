import React from "react";

import classes from './About.module.scss';

const About = React.memo(() => {
    return (
        <div className={classes.about}>
            <div className={classes.break}>About C-Leak</div>
            <p>The C-Leak project is all about giving the riddle game genre and its community what they really deserve.</p>
            
            <p>Together with a cypherpunk and hacking themed design, each player has the opportunity to unmask some fraudulent entities that just had their documents leaked by our black hat members. Players can show their skill by discovering the password of encrypted files and by doing so, they earn respect, some dopamine and potentially a pile of crypto coins.</p>
            
            <p>Listening to the community is a key factor on improving experience as well as creating further content. The idea is to continuous delivery new rewarding challenges as the existing ones are being solved.</p>

            <p>We are very excited to hear from you all and we hope you have a great experience playing this game.</p>
        </div>
    );
});

export default About;