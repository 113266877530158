import React, { useEffect, useState } from "react";

import classes from './TypeWriter.module.scss';

const TypeWriter = React.memo(({ text, onFinished }) => {
    const [letterCount, setLetterCount] =  useState(0);

    useEffect(() => {
        setLetterCount(0);
        const interval = setInterval(() => {
            setLetterCount((current) => {
                current++;
                if(current > text.length){
                    current = text.length;
                    clearInterval(interval);
                }
                return current;
            });
        }, 120);

        return () => {
            clearInterval(interval);
        };
    }, [text, onFinished, setLetterCount]);

    useEffect(() => {
        if(letterCount >= text.length){
            const timer = setTimeout(() => {
                onFinished();
            }, 3000);

            return () => {
                clearTimeout(timer);
            }
        }
    }, [text, letterCount, onFinished]);

    return (
    <div className={classes.textArea}>
        <span>{text.substring(0, letterCount)}</span><span className={ letterCount >= text.length ? classes.animatedCursor : classes.cursor}></span>
    </div>
    );
});

export default TypeWriter;