import React from "react";

import classes from './Footer.module.scss';
import twitterLogo from '../../assets/twitter.svg';
import facebookLogo from '../../assets/facebook.png';
import instagramLogo from '../../assets/instagram.png';

const Footer = React.memo(() => {
    return (<div className={classes.footer}>
        <div className={classes.socialNetworks}>
            <a href="https://twitter.com/c_leak_official"target="_blank" rel="noreferrer" ><img src={twitterLogo} alt="twitter"></img></a>
            <a href="https://www.facebook.com/c.leak.official"target="_blank" rel="noreferrer" ><img src={facebookLogo} alt="facebook"></img></a>
            <a href="https://www.instagram.com/c.leak.official"target="_blank" rel="noreferrer" ><img src={instagramLogo} alt="instagram"></img></a>
        </div>
        <div className={classes.links}>
            <a href="/home">Home</a>
            <a href="/howtoplay">How To Play</a>
            <a href="/about">About</a>
            <a href="/contact">Contact Us</a>
            <a href="/faq">FAQ</a>
        </div>
    </div>);
});

export default Footer;