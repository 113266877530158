import React from "react";

import classes from './Faq.module.scss';

const Faq = React.memo(() => {
    return (
        <div className={classes.faq}>
            <h1 className={classes.break}>FAQ</h1>
            <ul>
                <li>
                    <h3>I'm too good at this game and ended up being the first to put my hands on the crypto wallet full of coin. How do I transfer them to me?</h3>
                    <p>
                        If you have never transfered funds from a Bitcoin private key to your own wallet before, take a look at this <a target="_blank" rel="noreferrer" href="https://99bitcoins.com/bitcoin-wallet/paper/private-key-sweep-import/#Sweeping">Private Key Sweep</a> guide.
                        <br></br>
                        It is important to transfer out all funds to another address as soon as you get your hands on the private key. This way no one else can steal from you if they afterwards get access to that key.
                        <br></br>
                        If you do not have a crypto wallet yet, take a look at this <a target="_blank" rel="noreferrer" href="https://99bitcoins.com/bitcoin-wallet/paper/private-key-sweep-import/#Support">list of wallets</a> with private key import and sweep functionality.
                    </p>
                </li>
            </ul>
        </div>
    );
});

export default Faq;